<template>
    <div class="wrap">
        <sNav :list="navList" @setNav="setNav" :cls='5'></sNav>
        <div class="block" v-if="sList.length">
            <el-carousel height="150px">
                <el-carousel-item v-for="m in sList" :key="m.id">
                    <a :href="m.url ? m.url : 'javascript:;'">
                        <img class="s-img" :src="rImg(m.img)" :onerror="errImg" alt="">
                    </a>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="content-wrap">
            <artList :list="list"></artList>
            <div class="page-box" v-show="total">
                <el-pagination :pager-count="5" :page-size="20" @current-change="changePage" background layout="prev, pager, next" :total="total"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import sNav from "@components/common/nav";
    import artList from "@components/common/art-list";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            artList,
            sNav,
        },
        data() {
            return {
                list: [],
                navId: 0,
                sList: [],
                total: 0,
                navList: [],
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.setMeta("陷阱", 1);
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getNav();
            this.getData(1);
            this.getSwiper();
        },
        methods: {
            // 获取导航栏
            getNav() {
                let cate = this.$y_getKey("cate");
                if (cate) {
                    cate.unshift({
                        title: "推荐",
                        id: 0
                    });
                    this.navList = cate.splice(0, 9);
                }
            },
            // 切换栏目
            setNav(m) {
                this.navId = m.id;
                this.getData(1);
            },
            changePage(s) {
                this.getData(s);
            },
            // 获取列表
            async getData(page) {
                let param = {
                    category_id: this.navId, // 分类
                    class: 5, // 2经验 5陷阱
                };
                param.page = page || 1;

                let {
                    list,
                    total
                } = await this.$y_list("api/nihao/posts", [], param);
                this.list = list;
                if (total != -1) {

                    this.total = total;
                }
            },
            // 获取轮播
            async getSwiper() {
                let {
                    data
                } = await this.$y_ajax("api/Slide/lists", {
                    tid: 8,
                });
                this.sList = data;
            },
        },
        computed: {
            ...mapGetters(["s_getUser", "s_getCity"]),
        },
        watch: {
            s_getUser() {
                this.getData(1);
            },
            s_getCity() {
                this.getData(1);
            },
        },
    };
</script>

<style lang="less" scoped>
    .s-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
</style>